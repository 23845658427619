import { lockout } from "./utilities.js";
export default function mobileNav() {

    $('.l-mobile-nav:not(.js-nav-done)').each(function() {
        const wrapper = $(this);

        const menu = wrapper.find('.js-nav');
        const backGroup = wrapper.find('.js-nav-back-link');
        let level = 1;

        menu.find('.js-nav-link.has-sub').on('click', function() {
            // show sub nav
            const first = $(this).next('.js-sub-nav:first')
            first.addClass('visible');
            setTimeout(() => {
                // requestAniFrame was happening too quick, or the browser was batching them? Anyway the effect was lost, so setTimeout() to the rescue
                first.addClass('active');
            }, 16)

            level = 2;

            backGroup.addClass('show');
            return false;
        });

        menu.find('.js-sub-nav-title.has-sub').on('click', function() {
            // show sub nav group
            const first = $(this).parent().next('.js-sub-nav-group:first')
            first.addClass('visible');
            setTimeout(() => {
                first.addClass('active');
            }, 16)

            level = 3;
            backGroup.addClass('show');
            return false;
        });

        backGroup.on('click', function() {
            if (level === 2) {
                // hide sub nav
                wrapper.find('.js-sub-nav.active').one('transitionend', function() {
                    console.log($(this))
                    $(this).removeClass('visible');
                }).removeClass('active')

                level = 1;
                backGroup.removeClass('show');
            }
            else if (level === 3) {
                // hide sub nav group
                wrapper.find('.js-sub-nav-group.active').one('transitionend', function() {
                    console.log($(this))
                    $(this).removeClass('visible');
                }).removeClass('active')

                level = 2;
            }

            return false;
        });

        document.querySelector('#mobile-nav-toggle').addEventListener('click', function() {
            $('.l-mobile-nav').toggleClass('active')
            lockout.toggle()
        });

        // close click
        wrapper.find('.js-navsearch-link').on('click', function() {
            $('.l-mobile-nav').removeClass('active');
            lockout.off()
        });

        // done
        wrapper.addClass('js-nav-done');
    });
}
